/* eslint-disable no-useless-escape */

import React from 'react'
import styled from 'styled-components'
import Arrow from './arrow.svg'
import TransitionLink from 'gatsby-plugin-transition-link'
/*import VideoBackground from './video-background'*/

const NextInner = styled(TransitionLink)`
  display: block;
  position: relative;
  background-color: ${props => props.theme.colors.cream};
  text-decoration: none;
  color: initial;
  
  &:hover,
  &:focus {
    .next-project__background > img {
      transition: transform 3s ease-out;
      transform: scale(1.05);
    }
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    display: flex;
  }
`

const NextHeading = styled.div`
  position: relative;
  padding: 2.4rem 2.6rem;
  z-index: 1;
  
  span {
    display: block;
    font-family: ${props => props.theme.fonts.variable};
    font-weight: 380;
    font-size: 2.4rem;
    white-space: nowrap;
  }
  
  img {
    position: absolute;
    top: calc(100% - 2rem);
    left: 2rem;
    width: 4rem;
    transform-origin: bottom left;
    transform: rotate(90deg);
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 15.8rem 8rem 10.4rem 4.8rem;

    span {
      font-size: 7.2rem;
    }
    
    img {
      top: 18.8rem;
      left: calc(100% - 3.2rem);
      width: 12rem;
      transform: rotate(0);
    }
  }
`;

const NextWrap = styled.div`
  position: relative;
  flex: 1 1 66.6667%;
`;

const NextBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: ${props => props.theme.colors.blue};
  overflow: hidden;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
`

const NextContent = styled.div`
  position: relative;
  min-height: 14.4rem;
  padding: 5.6rem 2.6rem 3.2rem;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    flex: 1 1 44.8rem;
    padding: 18rem 4.8rem 6.4rem 12rem;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const NextName = styled.h2`
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 420;
  font-size: ${props => props.theme.fontSizes[6]}px;
  line-height: 1.1;
  margin: 0 0 1.6rem;
  
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    margin: 0 3.2rem 0 0;
  }
`;

const NextTagline = styled.p`
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 430;
  font-size: ${props => props.theme.fontSizes[4]}px;
  line-height: 1.06;
  margin: 0;
  max-width: 37.2rem;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 3.2rem;
    margin-top: 0.4rem;
  }
`;

const NextProject = ({ url, project }) => {
  const background = project.tout_image && project.tout_image.url ? project.tout_image : project.feature_image;

  return (
    <NextInner to={url}>
      <NextHeading>
        <span>Up next</span>
        <img src={Arrow} alt=''/>
      </NextHeading>

      <NextWrap>
        <NextBackground className="next-project__background">
          <img src={background.url} alt={background.alt}/>
        </NextBackground>

        <NextContent>
          <NextName>{project.name.text}</NextName>
          <NextTagline>{project.tagline.text}</NextTagline>
        </NextContent>
      </NextWrap>
    </NextInner>
  )
}

export default NextProject
