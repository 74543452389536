/* eslint-disable no-useless-escape */

import React from 'react'
import { Box, Heading, Text, Image } from 'rebass'
import styled from 'styled-components'
import VideoBackground from '../components/video-background'
/*import { window } from 'browser-monads'*/
import { isMobile } from "react-device-detect"

const HeaderInner = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  max-height: 120rem;
  overflow: hidden;
`

const HeaderBackground = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  background-color: #3d00ff;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const HeaderContent = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 100;
  color: #fff;
  padding: 12rem 2.6rem 6.4rem;
  
  .ProjectHeader--difference & {
    mix-blend-mode: difference;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 20rem 4.8rem 8rem;
  }
`

export const Title = styled(Heading)`
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 500;
  font-size: 2.2rem;
  
  .ProjectHeader--dark & {
    color: ${props => props.theme.colors.black};
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 2.4rem;
  }
`

export const Description = styled(Text)`
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 410;
  font-size: 3.2rem;
  line-height: 1.06;  
  max-width: 90rem;
  margin-top: ${props => props.theme.space[3]}px;

  .ProjectHeader--dark & {
    color: ${props => props.theme.colors.black};
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[7]}px;
    line-height: 1;
  }
`

const ProjectHeader = ({ project, variant }) => {
  const background = ((project.feature_video || project.feature_vimeo_video) && !isMobile) ?
    <VideoBackground
      poster={project.feature_image && project.feature_image.url}
      source={project.feature_video.url}
      vimeo={project.feature_vimeo_video}
    />
    :
    project.feature_image ?
      <Image
        src={project.feature_image.url}
        alt={project.feature_image.alt}
      />
      : null

  return (
    <HeaderInner className={variant ? `ProjectHeader--${variant}` : ''}>
      <HeaderBackground>
        {background}
      </HeaderBackground>

      <HeaderContent>
        <div>
          {project.name && <Title as="h1">{project.name.text}</Title>}
          {project.tagline && <Description as="h2">{project.tagline.text}</Description>}
        </div>
      </HeaderContent>
    </HeaderInner>
  )
}

export default ProjectHeader
