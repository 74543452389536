import React from 'react'
import { TransitionState } from 'gatsby-plugin-transition-link'
import posed from 'react-pose'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
/*import { window, document } from 'browser-monads'*/
import Layout from '../components/Layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/project-header'
import ProjectContent from '../components/project-content'
import NextProject from '../components/next-project'
/*import Fade from 'react-reveal/Fade'*/

const FadingContent = posed.div({
  exiting: { opacity: 0 },
})

const ProjectInner = ({ transitionStatus, project }) => {
  const nextProject = project.next_project.document.data;
  const overlayStyle = project.feature_overlay_style.toLowerCase()

  return (
    <Layout
      transitionStatus={transitionStatus}
      headerVariant={overlayStyle}
    >
      <SEO
        title={project.social_title || project.name.text || 'Another Amazing Case Study'}
        description={project.social_description}
        image={project.social_image}
      />

      <FadingContent pose={transitionStatus}>
        <ProjectHeader
          project={project}
          variant={overlayStyle}
        />

        <ProjectContent project={project}/>

        <NextProject
          url={project.next_project.url}
          project={nextProject}
        />
      </FadingContent>
    </Layout>
  )
}

const Project = (props) => {
  const project = props.data.prismicProject.data

  if (!project) {
    return null
  }

  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <ProjectInner
          transitionStatus={transitionStatus}
          project={project}
        />
      )}
    </TransitionState>
  )
}

export const query = graphql`
  query getProject($uid: String!) {
    prismicProject(uid: {eq: $uid}) {
      id
      data {
        feature_image {
          url
        }
        feature_video {
          url
        }
        feature_vimeo_video
        feature_overlay_style
        name {
          text
        }
        tagline {
          text
        }
        services {
          text
        }
        thesis
        project_link
        next_project {
          url
          document {
            ... on PrismicProject {
              id
              uid
                data {
                  feature_image {
                    url
                  }
                  tout_image {
                    url
                  }
                  name {
                    text
                  }
                  tagline {
                    text
                  }
                }
            }
          }
      }
      body {
        ... on PrismicProjectBodyRichTextSection {
          id
          slice_type
          primary {
            text {
              raw
            }
            size
          }
        }
        ... on PrismicProjectBodyImage {
          id
          slice_type
          primary {
            caption {
              text
              html
            }
            image {
              url
              alt
            }
            size
          }
        }
        ... on PrismicProjectBodyVideo {
          id
          slice_type
          primary {
            caption {
              text
              html
            }
            poster {
              url
            }
            source {
                url
            }
            vimeo_source
            size
            is_animated
          }
        }
        ... on PrismicProjectBodyRoadblock {
          id
          slice_type
          primary {
            caption {
              html
              text
            }
            image {
              alt
              url
            }
            video {
                url
            }
          }
        }
        ... on PrismicProjectBodyBlockquote {
          id
          slice_type
          primary {
            name1
            quote {
              text
            }
            size
            title
          }
        }
        ... on PrismicProjectBodySpacer {
          id
          slice_type
          primary {
            size
          }
        }
        }
      }
    }
  }
`

export default withPreview(Project)
