import React from 'react'
import styled from 'styled-components'
import SliceZone from './slice-zone'
import Arrow from './arrow.svg'

const Intro = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.darkGrey};
  color: #fff;
  width: 100%;
  padding: 8rem 2.6rem 6.4rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 18rem 4.8rem;
  }
`

const Services = styled.ul`
  list-style: none;
  display: block;
  padding-left: 0;
  
  li {
    display: inline-block;
    color: ${props => props.theme.colors.grey};
    font-family: ${props => props.theme.fonts.variable};
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.55;
    margin-right: 2.4rem;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    li {
      font-size: 1.8rem;
    }
  }
`

const Thesis = styled.h2`
  font-family: ${props => props.theme.fonts.variable};
  font-size: 2.7rem;
  font-weight: 410;
  line-height: 1.13;
  width: 80vw;
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
  max-width: 102.4rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 4.8rem;
    margin-top: 4.2rem;
    margin-bottom: 4rem;
  }
`

const ProjectLink = styled.a`
  display: inline-block;
  color: #fff;
  padding: 24px 0;
  margin: 0;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes[4]}px;
  text-decoration: none;
  
  img {
    position: relative;
    display: inline-block;
    margin-left: 8px;
    width: 64px;
    top: 4px;
  }
`

const ProjectContent = ({ project }) => {
  return (
    <>
      <Intro>
        {(project.services && project.services.length > 0) && (
          <Services>
            {project.services.map((service, i) => (
              <li key={`service-${i}`}>{service.text}</li>
            ))}
          </Services>
        )}

        {project.thesis && <Thesis>{project.thesis}</Thesis>}

        {project.project_link &&
        <ProjectLink href={project.project_link} rel="noreferrer" target="_blank">Visit site <img src={Arrow}
                                                                                                  alt=''/></ProjectLink>}
      </Intro>

      {project.body && (
        <SliceZone
          body={project.body}
        />
      )}
    </>
  )
}

export default ProjectContent
