/* eslint-disable no-useless-escape */

import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'rebass'
/*import VideoBackground from './video-background'*/
/*import { window } from 'browser-monads'*/
/*import { isMobile } from "react-device-detect"*/

const RoadblockContainer = styled(Box)`
  margin: 6.4rem 0;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    margin: 8.2rem 0;
  }
`

const RoadblockWrap = styled.div`
  position: relative;
  width: calc(100vw + 2px);
  left: calc(-50% - 1px);
  transform: translateX(50%);
  overflow: hidden;
`;

const RoadblockBackground = styled.div`
  position: relative;
  width: 100%;
  
  video, img {
    position: relative;
    width: 100%;
    height: auto;
  }
`;

const RoadblockContent = styled.div`
  padding: 0 2.6rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 0 8rem;
  }
`;

const Caption = styled(Text)`
  display: block;
  color: ${props => props.theme.colors.grey};
  font-size: 1.4rem;
  margin-top: 1.6rem;
  max-width: 60rem;
`

const Roadblock = ({ image, video, vimeo, caption }) => {
  let background;

  if (video && video.url) {
    background = <video
      autoPlay
      muted
      loop
      poster={image && image.url}
      playsInline
    >
      <source
        src={video.url}
        type={`video/${video.url.split('.').pop()}`}
      />
    </video>
  } else if (image && image.url) {
    background = <img src={image.url} alt=""/>;
  }

  return (
    <RoadblockContainer className={vimeo ? 'is-vimeo' : ''}>
      <RoadblockWrap>
        <RoadblockBackground>
          {background}
        </RoadblockBackground>
      </RoadblockWrap>

      <RoadblockContent>
        {caption && <Caption dangerouslySetInnerHTML={{ __html: caption.html }}/>}
      </RoadblockContent>
    </RoadblockContainer>
  )
}

export default Roadblock
