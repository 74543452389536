/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react'
import styled from 'styled-components'
import Content from './content'
import { Box, Flex, Image, Text } from 'rebass'
// import Fade from 'react-reveal/Fade'
import { Fade } from 'react-awesome-reveal'
import Roadblock from './roadblock'

const SliceZoneWrapper = styled.div`
  position: relative;
  background-color: #fff;
  padding: 3.2rem 0 8rem;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding-top: 10rem;
    padding-bottom: 14.4rem;
  }
`

const SliceZoneContainer = styled(Flex)`  
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 160rem;
  margin: 0 auto;
`

const Slice = styled(Box)`
  padding: 32px 2.6rem;
  width: 100%;
  
  &.Slice--spacer {
    display: none;
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    flex: ${props => props.layout === 'Half' ? '1 0 50%' : '1 0 100%'};
    padding: 12.2rem 5vw;

    &.Slice--spacer {
      display: block;
    }
  }
`

const Wrapper = styled(Box)`
  > div {
    max-width: ${props => props.layout === 'Half' ? '600px' : '820px'};
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    > div {
      margin: ${props => props.layout === 'Half' ? 'auto' : '0 auto'};
    }
  }
`

const Blockquote = styled(Box)`
  position: relative;
  max-width: ${props => props.layout === 'Half' ? '600px' : '820px'}; 
     
  h3 {
    font-size: 3.2rem;
    font-weight: 410;
    line-height: 1.13;
    margin: 0;
  }
  
  div {
    margin-top: 3.6rem;
  }
  
  h4 {
    font-size: 2rem;
    font-weight: 560;
    margin: 0 0 0.4rem;
    line-height: 1.2;
  }
  
  span {
    color: #333;
    font-size: 2rem;
    font-weight: 440;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    margin: ${props => props.layout === 'Half' ? 'auto' : '0 auto'};
    
    h3 {
      font-size: 4.8rem;
    }
  }
`

const Video = styled.div`
  max-width: 820px;
  margin: 0 auto;
  
  video,
  iframe {
    display: block;
    width: 100%;
    outline: 0 none;
  }
`

const VimeoVideo = styled.div`
  position: relative;
  width: 100%;
  
  &::after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
`

const Spacer = styled.div`
  position: relative;
  width: 100%;
  height: 14.4rem;
`

const Caption = styled(Text)`
  display: block;
  color: ${props => props.theme.colors.grey};
  font-size: 1.4rem;
  margin-top: 1.6rem;
  max-width: 60rem;
`

const SliceZone = ({ body }) => {

  return (
    <SliceZoneWrapper>
      <SliceZoneContainer>
        {body.map((slice, i) => {
          switch (slice.slice_type) {
            case 'rich_text_section':
              return (
                <Slice
                  key={`slice-${i}-rich-text`}
                  layout={slice.primary.size}
                >
                  <Fade up distance="40px" delay="50ms">
                    <Wrapper layout={slice.primary.size}>
                      <Content content={slice.primary.text.raw}/>
                    </Wrapper>
                  </Fade>
                </Slice>
              )

            case 'image':
              return (
                <Slice
                  key={`slice-${i}-image`}
                  layout={slice.primary.size}
                >
                  <Fade up distance="80px">
                    <>
                      <Image src={slice.primary.image.url} alt={slice.primary.image.alt}/>
                      {slice.primary.caption && <Caption dangerouslySetInnerHTML={{ __html: slice.primary.caption.html }}/>}
                    </>
                  </Fade>
                </Slice>
              )

            case 'video':
              return (
                <Slice
                  key={`slice-${i}-video`}
                  layout={slice.primary.size}
                >
                  {(slice.primary.source || slice.primary.vimeo_source) && (
                    <Video>
                      {(slice.primary.source && slice.primary.source.url.length > 0) ? (
                        <video
                          autoPlay={slice.primary.is_animated}
                          muted={slice.primary.is_animated}
                          loop={slice.primary.is_animated}
                          controls={!slice.primary.is_animated}
                          poster={slice.primary.poster && slice.primary.poster.url}
                          playsInline
                        >
                          <source
                            src={slice.primary.source.url}
                            type={`video/${slice.primary.source.url.split('.').pop()}`}
                          />
                        </video>
                      ) : (
                        <VimeoVideo>
                          <iframe
                            src={`https://player.vimeo.com/video/${slice.primary.vimeo_source}${slice.primary.is_animated ? `?background=1` : ''}`}
                            frameBorder="0"
                            allow={slice.primary.is_animated ? 'autoplay; fullscreen' : 'fullscreen'}
                            allowFullScreen
                            title={slice.primary.caption ? slice.primary.caption : 'Project video'}
                          />
                        </VimeoVideo>
                      )}

                      {slice.primary.caption && <Caption dangerouslySetInnerHTML={{ __html: slice.primary.caption.html }}/>}
                    </Video>
                  )}
                </Slice>
              )

            case 'roadblock':
              return (
                <Roadblock
                  image={slice.primary.image}
                  video={slice.primary.video}
                  caption={slice.primary.caption}
                  key={`slice-${i}-roadblock`}
                />
              )

            case 'blockquote':
              return (
                <Slice
                  key={`slice-${i}-blockquote`}
                  layout={slice.primary.size}
                >
                  <Fade up distance="80px">
                    <Blockquote>
                      <h3>{slice.primary.quote.text}</h3>

                      <div>
                        {slice.primary.name1 && <h4>{slice.primary.name1}</h4>}
                        {slice.primary.title && <span>{slice.primary.title}</span>}
                      </div>
                    </Blockquote>
                  </Fade>
                </Slice>
              )

            case 'spacer':
              return (
                <Slice
                  key={`slice-${i}-spacer`}
                  layout={slice.primary.size}
                  className="Slice--spacer"
                >
                  <Spacer/>
                </Slice>
              )

            default:
              return null
          }
        })}
      </SliceZoneContainer>
    </SliceZoneWrapper>
  )
}

export default SliceZone
